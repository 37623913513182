import React, { useEffect } from "react";
import "./styles.scss";

import getResultTiles from "../../utilities/getResultTiles";
import getSummary from "../../utilities/getSummary";
import getFirstUnansweredQuestionUrl from "../../utilities/getFirstUnansweredQuestionUrl";
import Tracking from "../../utilities/tracking";

import cfHImage from "../../assets/images/cfh.png";

import Anchor from "../Anchor";
import HandGlobe from "../HandGlobe";
import ExternalLinks from "../ExternalLinks";

/* eslint-disable react/jsx-no-target-blank */

const Tile = ({ item, grade }) => {
  return (
    <li className="tile">
      <div className={`tile__header tile__header--${item.meta.step}`}>
        <span className="tile__section--bold">Section {item.meta.step} </span> |{" "}
        {item.meta.name}
      </div>
      <div className="tile__content">
        <h2 className="tile__title">
          Your score: {item.score}/{item.answeredScoreCap}
        </h2>
        <p className="tile__description">{item.meta.result[grade]}</p>
        <span className="tile__links-wrapper">
          <h5>Links to help you level up:</h5>
          <ExternalLinks links={item.meta.resources} />
        </span>

        <div className="tile__image-wrapper">
          <img
            src={require(`../../assets/images/${item.id}.svg`)}
            alt={item.meta.name}
            className="tile__image"
            loading="lazy"
          />
        </div>
      </div>
    </li>
  );
};

const Results = ({ totalScore, rawScores, onParamChange }) => {
  useEffect(() => {
    // Pageview
    Tracking.trackEvent({
      event: "pageview",
    });
    if (typeof onParamChange == "function") {
      onParamChange({ questionId: null, sectionUrl: null });
    }
  }, [onParamChange]);

  if (!totalScore) {
    return null;
  }

  const results = getResultTiles(totalScore);
  const { heading, copy } = getSummary(totalScore.total.percent);
  const unansweredQuestionURL = getFirstUnansweredQuestionUrl(rawScores);
  const unansweredQuestions =
    totalScore.total.questionCount - totalScore.total.answered;

  const copyToClipboard = () => {
    window.navigator.clipboard.writeText(window.location.href).then(
      () => {
        alert("Link copied to clipboard.");
      },
      () => {
        alert(
          "Could not copy to clipboard. Please copy the page URL from the address bar to share your result"
        );
      }
    );
  };

  return (
    <div className="page results page--results" id="score">
      <div className="results__hero-wrap">
        <div className="results__hero">
          <div className="results__score">
            <div className="results__title">
              Your Universal Score:
              <br />
              <span className="results__score-numbers">
                <sup className="results__result">{totalScore.total.score}</sup>/
                <sub>{totalScore.total.answeredScoreCap}</sub>
              </span>
            </div>
          </div>
          <div className="results__description">
            <h2 className="results__description-title">{heading}</h2>
            <p className="results__blurb">{copy}</p>
            {window.navigator && window.navigator.clipboard ? (
              <button
                className="button results__share"
                onClick={copyToClipboard}
                data-track-category="Results"
                data-track-description="share-your-result"
              >
                Share result
              </button>
            ) : (
              <p className="results__blurb">
                Copy the page url to share your result
              </p>
            )}
          </div>
          <div className="results__image-wrapper">
            <div className="results__image--container">
              <div className="results__image">
                <HandGlobe animation={false} color="#fff" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {totalScore && (
        <>
          <div className="results__content">
            {results.length > 0 && (
              <>
                <ul className="results__list">
                  {results.map((item) => (
                    <Tile key={item.id} grade={item.grade} item={item} />
                  ))}
                </ul>
              </>
            )}
          </div>

          {unansweredQuestions > 0 && (
            <div className="results__footer-item">
              <div className="results__footer-item-container">
                <div className="results__footer-item-title">
                  <h2>Make your score more accurate</h2>
                </div>
                <div className="results__footer-item-content">
                  <p>
                    You've skipped {unansweredQuestions} question
                    {unansweredQuestions === 1 ? "" : "s"}. Would you like to
                    answer them? This would make your Universal Score even more
                    relevant to the problem you're trying to solve.
                  </p>
                  <Anchor
                    target={unansweredQuestionURL}
                    data-track-category="Results"
                    data-track-description="answer-skipped-questions"
                  >
                    Answer skipped questions
                  </Anchor>
                </div>
              </div>
            </div>
          )}
          <div className="results__footer-item">
            <div className="results__footer-item-container">
              <div className="results__footer-item-title">
                <h2>Want to score something else?</h2>
              </div>
              <div className="results__footer-item-content">
                <p>
                  Test multiple ideas to compare scores or try the same product
                  or service again to see how much you've improved.
                </p>
                <Anchor
                  target="/"
                  data-track-category="Results"
                  data-track-description="answer-skipped-questions"
                >
                  Start over
                </Anchor>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="results__footer-item results__footer-item--inverse">
        <div className="results__footer-item-container">
          <div className="results__footer-item-content results__footer-item-content--first-panel  results__footer-item-content--small-text">
            <h2 className="cfh-promo__title">Design for more people</h2>
            <p className="cfh-promo__description">
              How else can you bring more inclusive thinking to your problem?
              Cards for Humanity can help you inspire empathy and overcome
              unconscious bias.
            </p>
            <ExternalLinks inverse links={[{
              link: 'https://cardsforhumanity.frog.co/',
              copy: 'Play Cards for Humanity online',
              trackDescription: 'design for more people - play cards for humanity online',
            }, {
              link: 'https://www.figma.com/community/plugin/896347534161970744/Cards-for-Humanity',
              copy: 'Try the Figma plugin',
              trackDescription: 'design for more people - try the figma plugin',
            }]} />
          </div>
          <div className="results__footer-item-content">
            <img src={cfHImage} alt="Cards for Humanity" loading="lazy" />
          </div>
        </div>
      </div>

      <div className="results__footer-item results__footer-item--frog-promo">
        <div className="results__footer-item-container">
          <div className="results__footer-item-title results__footer-item-title">
            <h2>
              frog is the reinvention and experience partner for the eco&#8209;digital era.
            </h2>
          </div>
          <div className="results__footer-item-content results__footer-item-content--small-text">
            <p>
              Together with our clients, we innovate with purpose, using strategy, creativity, tech, data and AI to advance people and planet.
            </p>
            <p>
              <ExternalLinks links={[{
                link: 'mailto:cardsforhumanity@frog.co?subject=Universal Score workshop',
                copy: 'Ask us about a workshop',
                trackDescription: 'frog - ask us about a workshop',
              }]} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Results;
